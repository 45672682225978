.layout-container {
  position: relative;

  &-full {
    width: 100%;
    max-width: 100%;
    margin: 0;
    padding: 0;
  }

  &-mt-0 {
    margin-top: 0;
  }
}
