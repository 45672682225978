.button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: none;
  color: #fff;
  transition: 0.2s opacity ease;
  appearance: none;
  outline: none;
  cursor: pointer;

  &:hover {
    &::after {
      opacity: 1;
    }
  }

  &:active {
    opacity: 0.8;
  }

  &::after {
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.3s opacity ease;
    border-radius: 5px;
    opacity: 0.2;
  }

  &__loader-text-wrapper {
    position: relative;
  }
  &__spinner-wrapper {
    position: absolute;
    top: -1px;
    right: calc(100% + 8px);
  }

  &[data-disabled='true'] {
    opacity: 0.3;
  }

  &[data-hidden='true'] {
    // stylelint-disable-next-line declaration-no-important
    display: none !important;
  }

  &--success {
    width: 100%;
    height: max-content;
    padding: 10px 0;
    background: var(--button-type-success__bg);

    border: 0;
    border-radius: 6px;
    color: var(--button-type-success__color);
    cursor: pointer;

    font-family: Arial, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.04em;
    text-transform: uppercase;

    &:disabled,
    &[data-disabled='true'] {
      cursor: not-allowed;
      opacity: 0.7;
    }

    &:hover {
      // stylelint-disable-next-line declaration-no-important
      color: var(--button-type-success__color) !important;
      filter: brightness(0.9);
    }
  }
}
