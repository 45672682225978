.auth-form {
  width: 100%;
  max-width: 360px;
  margin: auto;
  @include pxToEm(margin-top, 50);
  @include pxToEm(margin-bottom, 50);

  position: relative;

  &_title {
    // @include pxToEm(padding-top, 40);
    @include pxToEm(margin-bottom, 20);
    @include font(22, 22, 700, var(--auth-forms-title-color));
    text-align: center;
  }

  &_social_buttons {
    @include flexbox($justifyContent: center, $alignItems: center, $wrap: wrap);
    @include pxToEm(margin-top, 8);
    button {
      margin: 5px auto !important;
    }
  }

  &_social_title {
    text-align: center;
    @include pxToEm(margin-top, 10);
    @include font(16, 14, 400, var(--auth-forms__socialtitle-color));
  }

  &_wrapper {
    @include flexbox($justifyContent: center, $alignItems: center, $flow: column);
    @include pxToEm(padding, 25);

    position: relative;

    border: var(--auth-forms-border);

    z-index: 1;
    transition: 0.12s ease-out;
    background: var(--auth-forms-bg);
    backdrop-filter: blur(4px);
    border-radius: 16px;

    box-shadow: var(--auth-forms-shadow);
    form {
      width: 100%;
    }
  }

  &_field {
    width: 100%;

    @include pxToEm(margin-bottom, 20);

    &:last-child {
      margin-bottom: 0;
    }

    input {
      width: 100%;
      background: var(--auth-forms__input-bg) !important;
      border: 0;
      border-radius: 5px;
      color: #fff;
      margin: 0;
      padding: 8px 15px;
      height: 45px;
      font-weight: 400 !important;

      &::placeholder {
        @include font(16, 16, 400, rgb(255 255 255 / 15%));
      }
    }
    .react-international-phone-input-container {
      .react-international-phone-dial-code-preview,
      .react-international-phone-country-selector {
        background: var(--auth-forms__input-bg) !important;
      }
    }

    .ant-input-password {
      background: var(--auth-forms__input-bg) !important;
      border: 0;
      height: 45px;

      input {
        height: 100%;
      }
    }
  }

  .or {
    @include flexbox($justifyContent: center, $alignItems: center, $flow: row nowrap);
    @include font(12, 12, 500, #fff);
  }

  &_buttons {
    @include pxToEm(margin-top, 30);
    width: 100%;
    &.reset-buttons {
      margin-top: 0;
      .submit-button {
        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }
    }

    .button {
      @include font(14, 14, 400, #fff);
      border-radius: 5px;

      cursor: pointer;
      width: 100%;
      height: 100%;

      text-align: center;
      transition: 0.3s color ease-in-out;
    }
  }

  .submit-button {
    @include pxToEm(padding, 12);

    background: var(--auth-forms__button-success-bg);
    color: var(--auth-forms__button-success-color);
    text-transform: uppercase;

    &-disabled {
      filter: brightness(0.8);
      background: #ffffff26;
    }
  }

  .restore-button {
    @include pxToEm(margin, 5 0 0);

    background-color: var(--auth-forms__button-forgot-bg);
    color: var(--auth-forms__button-forgot-color);
    text-decoration: underline;
  }

  .signin-button {
    @include pxToEm(margin-top, 30);
    @include pxToEm(padding, 12);

    background: var(--auth-forms__button-reg-bg);
    color: var(--auth-forms__button-reg-color);
    text-transform: uppercase;

    a {
      text-decoration: none;
    }
  }

  .error {
    @include font(14, 14, 500, rgb(255 0 0));
    @include pxToEm(margin, 5 0 0 0);
    text-transform: capitalize;
    text-align: left;
  }

  .handle-back-in-form {
    @include flexbox($justifyContent: center, $alignItems: center);
    @include pxToEm(margin, 20 0);
  }

  .telegram-auth {
    @include pxToEm(margin, 5 0 0);
    @include flexbox($justifyContent: center, $alignItems: center, $wrap: wrap);
  }

  &-tabs {
    @include pxToEm(margin, 10 0);

    & .tab {
      &__nav {
        @include pxToEm(margin-bottom, 10);

        gap: 10px;

        padding: 0;

        .name {
          @include pxToEm(padding, 8 12);
          @include font(16, 14, 500, #fff);

          width: 100%;
          justify-content: center;

          background: linear-gradient(180deg, #7720ac 0%, #280f4f 100%);
          box-shadow: 0 4px 70px 0 rgba(163, 27, 246, 0.4);
          border: 2px solid #3fbd53;
          border-radius: 5px;

          text-transform: none;

          transition: 0.4s ease background;

          &.active {
            background: #262626;
          }
        }
      }
    }
  }

  &.loader-form {
    min-height: 50vh;
  }
}
